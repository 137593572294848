<div
    class="card bg-white flex-grow-1 shadow-sm h-100"
    [style.border-radius]="'1.75rem'"
    [style.max-width.px]="440"
    [style.aspect-ratio]="1 / 0.628"
>
    <div class="card-body px-4 vstack justify-content-between">
        <div class="vstack flex-grow-0">
            <div class="d-block w-100">
                <div
                    class="text-wrap fw-semibold text-uppercase text-end text-primary"
                    appVenueColour
                    [primaryColour]="venue.primaryColour"
                    style="letter-spacing: 1px;"
                >
                    {{ venue.displayName }}
                </div>
            </div>
            <div class="d-block w-100">
                <div class="text-truncate text-uppercase text-end text-primary"
                     appVenueColour
                     [primaryColour]="venue.primaryColour"
                     style="letter-spacing: 1px;"
                >
                    {{ voucher.displayName }}
                </div>
            </div>
        </div>
        <div class="hstack gap-5 justify-content-between">
            <div class="vstack">
                <div class="text-muted">
                    Value
                </div>
                <div
                    style="font-family: monospace; letter-spacing: 0.1em;"
                >
                    @if (sku !== null) {
                        {{ null | currencyUnit : sku.price : venue.currencyCode || "GBP" }}
                    } @else {
                        <div
                            class="bg-light rounded"
                            [style.color]="'transparent'"
                        >
                            {{ 'N/A Price' }}
                        </div>
                    }
                </div>
            </div>
            <div class="text-end">
                <div class="vstack">
                    <span class="text-muted">
                        Expiry Date
                    </span>
                    <span *ngIf="voucher.expiryMonths !== null; else noExpiry">
                        {{ expiryDateFromNow | date:'dd/MM/yyyy' }}
                    </span>
                    <ng-template #noExpiry>
                        N/A
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
