import { LineItem } from './LineItem'

export class Order {

    constructor(
        public id: string,
        public lineItems: LineItem[],
        public currencyCode: string
    ) { }

    total(): number {
        return this.lineItems.reduce((acc, lineItem) => {
            return acc + (lineItem.amount * lineItem.quantity)
        }, 0)
    }
}
