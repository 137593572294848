import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BookingReason } from '../../../domain/BookingReason'
import { Event } from '../../../domain/Event'

@Component({
    selector: 'app-reason-card',
    templateUrl: './reason-card.component.html',
})
export class ReasonCardComponent {

    @Input() reason!: BookingReason
    @Input() date!: Date
    @Input() isSelected!: boolean
    @Input() canSelect!: boolean
    @Output() reasonSelected = new EventEmitter<void>()
    showMore: boolean = false
    characterLimit: number = 140
}
