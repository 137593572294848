<label
    class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
    data-testid="reason-select"
>
    <div class="m-1 hstack gap-2 text-start">
        <input
            type="radio"
            class="form-check-input m-0"
            role="button"
            [value]="reason.id"
            [checked]="isSelected"
            (change)="reasonSelected.emit()"
        >
        <div class="vstack align-items-start">
            <div class="hstack gap-2 justify-content-between me-1">
                    <span class="fw-semibold">
                        {{ reason.displayName }}
                    </span>
                <span class="small text-secondary">
                        {{ reason | reasonTime : date }}
                    </span>
            </div>
            <span
                *ngIf="reason.description"
                class="small"
            >
                <span [innerHTML]="
                    showMore
                        ? reason.description
                        : (reason.description | slice :0 : characterLimit) + (reason.description.length > characterLimit ? '...' : '')
                ">
                </span>
                <button
                    *ngIf="reason.description.length > characterLimit"
                    class="btn btn-sm btn-link p-0 ms-1 lh-base"
                    (click)="showMore = !showMore"
                >
                    {{ showMore ? 'Read less' : 'Read more' }}
                </button>
            </span>
        </div>
    </div>
</label>
