import { booleanAttribute, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-voucher-modal-launcher',
    templateUrl: './voucher-modal-launcher.component.html',
})
export class VoucherModalLauncherComponent implements OnInit {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() voucherId!: string
    @Input({ transform: booleanAttribute }) autoOpen?: boolean

    ngOnInit() {
        if (this.autoOpen) {
            this.openModal()
        }
    }

    openModal() {
        window.parent.postMessage({
                type: 'open-voucher-modal',
                payload: {
                    organisationId: this.organisationId,
                    businessId: this.businessId,
                    venueId: this.venueId,
                    voucherId: this.voucherId,
                },
            },
            '*')
    }
}
