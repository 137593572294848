import { Order } from './Order'
import { DeliveryDetails } from './DeliveryDetails'

export class Sale {

    constructor(
        public id: string,
        public order: Order,
        public deliveryDetails: DeliveryDetails | null,
        public dateCreated: Date
    ) { }
}
