<div
    class="d-block modal fade show"
    appVenueColour
>
    @if (venue$ | async; as venue) {
        <div
            class="text-white small position-absolute bottom-0 d-none d-sm-flex gap-2 justify-content-center align-items-center w-100 p-3"
        >
            {{ venue.displayName }}
            collaborates with TableSense to simplify operations and create lasting guest experiences.
            <a
                [href]="marketingUrl"
                target="_blank"
            >
                Learn more
            </a>
        </div>
        <div class="modal-fullscreen-sm-down container p-0 pb-sm-5 d-flex flex-column mt-0 mb-0 align-items-center justify-content-center min-vh-100">
            <div class="modal-content m-0 mb-sm-3 overflow-hidden">
                <ng-container
                    *ngTemplateOutlet="header; context: { venue: venue }"
                >
                </ng-container>
                <ng-container
                    *ngTemplateOutlet="body; context: { venue: venue }"
                >
                </ng-container>
            </div>
        </div>
    }
</div>

<ng-template
    #header
    let-venue="venue"
>
    <div
        class="modal-header px-4 py-2 d-flex justify-content-between"
    >
        <h5 class="modal-title fw-semibold text-nowrap">
            @switch (form.get('step')?.value) {
                @case (VoucherModalStep.SKUSelection) {
                    <div class="py-1">
                        Purchase a {{ venue.displayName }} Voucher
                    </div>
                }
                @case (VoucherModalStep.Checkout) {
                    <div class="hstack gap-3 align-items-center">
                        <button
                            type="button"
                            class="btn btn-sm btn-outline-secondary"
                            (click)="goToSKUSelection()"
                        >
                            <img
                                ngSrc="assets/left/normal.svg"
                                width="16"
                                height="16"
                            />
                        </button>
                        Checkout
                    </div>
                }
                @case (VoucherModalStep.PaymentConfirmation) {
                    <div class="py-1 hstack gap-3 align-items-center">
                        Order Received
                    </div>
                }
            }
        </h5>
        <button
            type="button"
            class="btn-close"
            (click)="dismiss()"
        >
        </button>
    </div>
</ng-template>

<ng-template
    #body
    let-venue="venue"
>
    <div class="modal-body p-0 flex-fill vstack">
        @if (voucher$ | async; as voucher) {
            <ng-container
                [ngSwitch]="form.get('step')?.value"
            >
                <app-voucher-selection
                    *ngSwitchCase="VoucherModalStep.SKUSelection"
                    [venue]="venue"
                    [voucher]="voucher"
                    [form]="form"
                    (checkoutSelected)="goToCheckout()"
                >
                </app-voucher-selection>
                <app-voucher-checkout
                    *ngSwitchCase="VoucherModalStep.Checkout"
                    [venue]="venue"
                    [voucher]="voucher"
                    [form]="form"
                    (paymentConfirmed)="paymentConfirmed($event)"
                >
                </app-voucher-checkout>
                <ng-container
                    *ngSwitchCase="VoucherModalStep.PaymentConfirmation"
                >
                    @if (confirmedSale) {
                        <app-voucher-payment-confirmation
                            [venue]="venue"
                            [voucher]="voucher"
                            [sale]="confirmedSale"
                        >
                        </app-voucher-payment-confirmation>
                    }
                </ng-container>
            </ng-container>
        }
    </div>
</ng-template>
