import { Component, Input, OnInit } from '@angular/core'
import { environment } from '../../environments/environment'
import { Venue } from '../domain/Venue'
import { VenueService } from '../services/venue.service'
import { mergeMap, Observable } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Voucher } from '../domain/Voucher'
import { VoucherService } from '../services/voucher.service'
import { Helper } from '../utilities/helper'
import { Sale } from '../domain/Sale'

enum VoucherModalStep {
    SKUSelection = 'SKUSelection',
    Checkout = 'Checkout',
    PaymentConfirmation = 'PaymentConfirmation',
}

@Component({
    selector: 'app-voucher-modal',
    templateUrl: './voucher-modal.component.html',
})
export class VoucherModalComponent implements OnInit {

    @Input() organisationId!: string
    @Input() businessId!: string
    @Input() venueId!: string
    @Input() voucherId!: string
    form!: FormGroup
    isLoading = false
    marketingUrl = environment.marketingUrl
    venue$!: Observable<Venue>
    voucher$!: Observable<Voucher>
    confirmedSale: Sale | null = null

    constructor(
        private fb: FormBuilder,
        private venueService: VenueService,
        private voucherService: VoucherService
    ) { }

    ngOnInit() {
        this.venue$ = this.venueService.getWithId(this.venueId)
        this.voucher$ = this.venue$.pipe(
            mergeMap(venue => {
                return this.voucherService.getWithId(venue, this.voucherId)
            })
        )
        this.form = this.makeForm()
    }

    dismiss() {
        window.parent.postMessage(
            {
                type: 'close-booking-modal',
                payload: {
                },
            },
            '*'
        )
    }

    goToSKUSelection() {
        this.form.get('step')?.setValue(VoucherModalStep.SKUSelection)
    }

    goToCheckout() {
        this.form.markAllAsTouched()
        this.form.updateValueAndValidity()
        if (this.form.invalid) {
            return
        }
        this.form.get('step')?.setValue(VoucherModalStep.Checkout)
    }

    paymentConfirmed(sale: Sale) {
        this.confirmedSale = sale
        this.form.get('step')?.setValue(VoucherModalStep.PaymentConfirmation)
    }

    private makeForm() {
        return this.fb.group({
            skuId: [null, [Validators.required]],
            step: [VoucherModalStep.SKUSelection, [Validators.required]],
        })
    }

    protected readonly Helper = Helper
    protected readonly VoucherModalStep = VoucherModalStep
}
