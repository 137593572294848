import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
import { Injectable } from '@angular/core'
import { environment } from '../../environments/environment'

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    private browserAgent?: BrowserAgent

    setUp() {
        const applicationId = environment.newRelicApplicationId
        if (applicationId.length === 0) {
            return
        }
        const options = this.makeOptions()
        options.info.applicationID = applicationId
        options.loader_config.applicationID = applicationId
        options.loader_config.agentID = applicationId
        this.browserAgent = new BrowserAgent(options)
    }

    noticeError(error: Error) {
        if (this.browserAgent) {
            this.browserAgent.noticeError(error)
        }
    }

    private makeOptions() {
        return {
            init: {
                distributed_tracing: {
                    enabled: true,
                    cors_use_newrelic_header: false,
                    cors_use_tracecontext_headers: true,
                    allowed_origins: [environment.apiBaseUrl],
                },
                privacy: { cookies_enabled: true },
                ajax: { deny_list: ['bam.eu01.nr-data.net'] },
            },
            info: {
                beacon: 'bam.eu01.nr-data.net',
                errorBeacon: 'bam.eu01.nr-data.net',
                licenseKey: 'NRJS-42809ed77caeb693bfa',
                applicationID: '',
                sa: 1,
            },
            loader_config: {
                accountID: '4432793',
                trustKey: '4432793',
                agentID: '',
                licenseKey: 'NRJS-42809ed77caeb693bfa',
                applicationID: '',
            },
        }
    }
}
