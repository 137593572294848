export class LineItem {

    constructor(
        public id: string,
        public name: string,
        public skuId: string,
        public amount: number,
        public quantity: number
    ) { }
}
