import { AreaAvailability } from './AreaAvailability'
import { BookingSlot } from './BookingSlot'
import { BookingSlotAvailability } from './BookingSlotAvailability'
import { BookingSlotOption } from './BookingSlotOption'
import { Event } from './Event'
import { Venue } from './Venue'

export class Availability {

    private defaultMinimumReservableSeats = 2
    private defaultMaximumReservableSeats = 10

    constructor(
        public areas: AreaAvailability[],
        public venue: Venue
    ) { }

    get areasSortedByDisplayOrder() {
        return this.areas.sort((a, b) => {
            return a.area.displayOrder - b.area.displayOrder
        })
    }

    hasBookingSlotsInsideCutOffTimeForAreaId(areaId?: string): boolean {
        return this.areasWithBookingSlotsHiddenDueToCutOffTime(areaId).length > 0
    }

    smallestActivePreBookingWindow(areaId?: string): number | null {
        const affectedAreas = this.areasWithBookingSlotsHiddenDueToCutOffTime(areaId)
        if (affectedAreas.length === 0) {
            return null
        }
        const cutOffDurations = affectedAreas
            .map(areaAvailability => {
                return areaAvailability.area.preBookingWindowMinutes
            })
        const smallestCutOffDuration = Math.min(...cutOffDurations)
        if (smallestCutOffDuration === Infinity) {
            return null
        }
        return smallestCutOffDuration
    }

    bookableAreas(): AreaAvailability[] {
        return this.areasSortedByDisplayOrder.filter(areaAvailability => {
            return areaAvailability.areaCanBeBooked
        })
    }

    earliestAvailability(): BookingSlotAvailability | null {
        return this.areas
            .flatMap(areaAvailability => {
                return areaAvailability.bookingSlots
            })
            .filter(bookingSlot => bookingSlot.options.length > 0)
            .sort((a, b) => {
                return a!.bookingSlot.dateTime.getTime() - b!.bookingSlot.dateTime.getTime()
            })[0] ?? null
    }


    earliestBookingSlot(): BookingSlot | null {
        return this.areas
            .map(areaAvailability => {
                return areaAvailability.earliestBookingSlot()
            })
            .filter(bookingSlot => bookingSlot !== null)
            .sort((a, b) => {
                return a!.dateTime.getTime() - b!.dateTime.getTime()
            })[0] ?? null
    }

    latestBookingSlot(): BookingSlot | null {
        return this.areas
            .map(areaAvailability => {
                return areaAvailability.latestBookingSlot()
            })
            .filter(bookingSlot => bookingSlot !== null)
            .sort((a, b) => {
                return b!.dateTime.getTime() - a!.dateTime.getTime()
            })[0] ?? null
    }

    get intervalIdealTimes(): Date[] {
        const start = this.earliestBookingSlot()?.dateTime
        if (!start) {
            return []
        }
        const end = this.latestBookingSlot()?.dateTime
        if (!end) {
            return []
        }
        const bookingInterval = this.venue.bookingInterval
        const times: Date[] = []
        let startTime = new Date(start)
        for (let time = startTime; time <= end; time.setMinutes(time.getMinutes() + bookingInterval)) {
            if (!this.hasBookingSlotAtDate(time)) {
                continue
            }
            times.push(new Date(time))
        }
        return times
    }

    hasBookingSlotAtDate(date: Date): boolean {
        const bookingSlot = new BookingSlot(date)
        return this.areas.some(areaAvailability => {
            return areaAvailability.hasBookingSlot(bookingSlot)
        })
    }

    get possiblePartySizes(): number[] {
        const min = this.minimumReservablePartySize()
        let max = this.maximumReservablePartySize()
        return Array.from({ length: max - min + 1 }, (_, i) => i + min)
    }

    minimumReservablePartySize(): number {
        return Math.min(
            this.minimumBookingSlotPartySize(),
            ...this.areas.flatMap(areaAvailability => {
                return areaAvailability.area.minimumReservableSeats()
            })
        )
    }

    defaultReservablePartySize(): number {
        return Math.max(
            this.defaultMinimumReservableSeats,
            this.minimumBookingSlotPartySize()
        )
    }

    maximumReservablePartySize(): number {
        const max = Math.max(
            this.maximumBookingSlotPartySize(),
            ...this.areas.flatMap(areaAvailability => {
                return areaAvailability.area.maximumReservableSeats()
            })
        )
        if (this.venue.minLargePartySize !== null) {
            return Math.min(max, this.venue.minLargePartySize - 1)
        }
        return max
    }

    isConfigurationReservable(
        partySize: number,
        reasonId: string | null,
        eventId: string | null
    ): boolean {
        return this.areas.some(areaAvailability => {
            return this.areaIsReservableWithConfiguration(
                areaAvailability.area.id,
                partySize,
                reasonId,
                eventId
            )
        })
    }

    doesConfigurationHaveAnyReservableReasons(partySize: number, idealTime: Date): boolean {
        return this.venue.reasonsUsedOnDate(idealTime).some(reason => {
            return this.areas.some(areaAvailability => {
                return this.areaIsReservableWithConfiguration(
                    areaAvailability.area.id,
                    partySize,
                    reason.id,
                    null
                )
            })
        })
    }

    areaIsConfiguredForChoices(
        areaId: string,
        reasonId: string | null,
        eventId: string | null
    ): boolean {
        const areaAvailability = this.areas.find(areaAvailability => {
            return areaAvailability.area.id === areaId
        })
        if (!areaAvailability) {
            return false
        }
        if (reasonId) {
            const reason = this.venue.reasonWithId(reasonId)
            if (!reason) {
                return false
            }
            if (!areaAvailability.isConfiguredForReason(reason)) {
                return false
            }
        }
        if (eventId) {
            const event = this.venue.eventWithId(eventId)
            if (!event) {
                return false
            }
            if (!areaAvailability.isConfiguredForEvent(event)) {
                return false
            }
        }
        return true
    }

    areaIsReservableWithConfiguration(
        areaId: string,
        partySize: number,
        reasonId: string | null,
        eventId: string | null
    ): boolean {
        const areaAvailability = this.areas.find(areaAvailability => {
            return areaAvailability.area.id === areaId
        })
        if (!areaAvailability) {
            return false
        }
        const reason = reasonId ? this.venue.reasonWithId(reasonId) : null
        const event = eventId ? this.venue.eventWithId(eventId) : null
        return areaAvailability.isReservableWithConfiguration(partySize, reason, event)
    }

    availableEvents(): Event[] {
        return this.venue.events.filter(event => {
            return this.areas.some(areaAvailability => {
                return areaAvailability.bookingSlots.some(bookingSlotAvailability => {
                    return bookingSlotAvailability.options.some(option => {
                        return option.eventId === event.id
                    })
                })
            })
        })
    }

    reservableEvents(): Event[] {
        return this.venue.events.filter(event => {
            return this.areas.some(areaAvailability => {
                return areaAvailability.bookingSlots.some(bookingSlotAvailability => {
                    return bookingSlotAvailability.options.some(option => {
                        return option.eventId === event.id && option.reservableTableCount > 0
                    })
                })
            })
        })
    }

    hasReservableStandardBookingOptions(): boolean {
        const hasNonEventOptions = this.areas.some(areaAvailability => {
            return areaAvailability.bookingSlots.some(bookingSlotAvailability => {
                return bookingSlotAvailability.options.some(option => {
                    return option.eventId === null
                })
            })
        })
        const eventIds = this.configuredEventIds()
        if (eventIds.length === 0) {
            return hasNonEventOptions
        }
        const exclusiveEvents = this.venue.events
            .filter(event => event.isExclusive)
            .filter(event => eventIds.includes(event.id))
        const optionsPerBookingSlot = this.allAreaOptionsPerBookingSlot()
        const everyBookingSlotHasExclusiveEvent = optionsPerBookingSlot.every(options => {
            return options.some(option => {
                return exclusiveEvents.some(event => {
                    return option.eventId === event.id
                })
            })
        })
        if (everyBookingSlotHasExclusiveEvent) {
            return false
        }
        return hasNonEventOptions
    }

    reservableOptionWithConfiguration(
        areaId: string,
        partySize: number,
        reasonId: string | null,
        eventId: string | null,
        dateTime: Date
    ): BookingSlotOption | null {
        const areaAvailability = this.getAvailabilityByAreaId(areaId)
        if (!areaAvailability) {
            return null
        }
        const reason = reasonId ? this.venue.reasonWithId(reasonId) : null
        const event = eventId ? this.venue.eventWithId(eventId) : null
        return areaAvailability.reservableOptionWithConfiguration(
            partySize,
            reason,
            event,
            dateTime
        )
    }

    noAvailabilityDescription(
        requiresDogFriendly: boolean | null,
        partySize: number,
        selectedAreaId: string | null,
        selectedEventId: string | null
    ): string | null {
        if (requiresDogFriendly === true && this.venue.dogFriendlyNoAvailabilityMessage !== null) {
            return this.venue.dogFriendlyNoAvailabilityMessage
        }
        if (partySize && selectedEventId) {
            const event = this.venue.eventWithId(selectedEventId)
            if (event && !event.isPartySizeAllowed(partySize)) {
                const eventIsReservableWithOtherPartySizes = this.reservableEvents()
                    .find(event => event.id === selectedEventId) !== undefined
                if (eventIsReservableWithOtherPartySizes) {
                    if (event.partySizeRestrictedMessage) {
                        return event.partySizeRestrictedMessage
                    }
                }
            }
        }
        if (selectedAreaId) {
            return this.getAvailabilityByAreaId(selectedAreaId)?.exceptionDescription
                ?? this.venue.noBookingSlotAvailableMessage
        }
        const firstExceptionDescription = this.areasSortedByDisplayOrder
            .map(areaAvailability => {
                return areaAvailability.exceptionDescription
            })
            .find(description => description !== null)
        if (firstExceptionDescription !== undefined) {
            return firstExceptionDescription
        }
        return this.venue.noBookingSlotAvailableMessage
    }

    notReservableOnlineMessage(eventId: string | null): string | null {
        if (!eventId) {
            return null
        }
        const event = this.venue.eventWithId(eventId)
        if (!event) {
            return null
        }
        return event.notReservableOnlineMessage
    }

    hasARestrictedTable(): boolean {
        return this.hasAWheelchairRestrictedTable() || this.hasADogRestrictedTable()
    }

    hasAWheelchairRestrictedTable(): boolean {
        return this.areas.some(areaAvailability => {
            return areaAvailability.area.hasAWheelchairRestrictedTable()
        })
    }

    hasADogRestrictedTable(): boolean {
        return this.areas.some(areaAvailability => {
            return areaAvailability.area.hasADogRestrictedTable()
        })
    }

    private minimumBookingSlotPartySize(): number {
        const minimum = Math.min(
            ...this.areas.flatMap(areaAvailability => {
                return areaAvailability.bookingSlots.flatMap(bookingSlotAvailability => {
                    return bookingSlotAvailability.options.map(option => {
                        return option.partySize
                    })
                })
            })
        )
        if (minimum === Infinity) {
            return this.defaultMinimumReservableSeats
        }
        return minimum
    }

    private maximumBookingSlotPartySize(): number {
        const maximum = Math.max(
            ...this.areas.flatMap(areaAvailability => {
                return areaAvailability.bookingSlots.flatMap(bookingSlotAvailability => {
                    return bookingSlotAvailability.options.map(option => {
                        return option.partySize
                    })
                })
            })
        )
        if (maximum === Infinity) {
            return this.defaultMaximumReservableSeats
        }
        return maximum
    }

    private getAvailabilityByAreaId(selectedAreaId: string) {
        return this.areas.find(areaAvailability => {
            return areaAvailability.area.id === selectedAreaId
        }) ?? null
    }

    private areasWithBookingSlotsHiddenDueToCutOffTime(areaId?: string): AreaAvailability[] {
        const possibleAreas = areaId ? this.areas.filter(area => {
            return area.area.id === areaId
        }) : this.areas
        const bookingSlotsInsideCutOffTime = possibleAreas
            .flatMap(areaAvailability => {
                return areaAvailability.bookingSlotsInsideCutOffTime()
            })
        const bookingSlotsHiddenByCutOffTime = bookingSlotsInsideCutOffTime
            .filter(bookingSlotAvailability => {
                return possibleAreas.every(areaAvailability => {
                    return areaAvailability.area.cutOffTime.getTime() > bookingSlotAvailability.bookingSlot.dateTime.getTime()
                })
            })
        return possibleAreas
            .filter(areaAvailability => {
                return bookingSlotsHiddenByCutOffTime.some(bookingSlot => {
                    return areaAvailability.hasBookingSlot(bookingSlot.bookingSlot)
                })
            })
    }

    private allAreaOptionsPerBookingSlot() {
        const bookingSlots = this.areas
            .flatMap(areaAvailability => {
                return areaAvailability.bookingSlots.flatMap(bookingSlotAvailability => {
                    return bookingSlotAvailability.bookingSlot
                })
            })
            .filter((value, index, self) => {
                return self.indexOf(value) === index
            })
        return bookingSlots.map(bookingSlot => {
            return this.areas.flatMap(areaAvailability => {
                return areaAvailability.bookingSlots.find(bookingSlotAvailability => {
                    return bookingSlotAvailability.bookingSlot.dateTime.getTime() === bookingSlot.dateTime.getTime()
                })?.options ?? []
            })
        })
    }

    private configuredEventIds() {
        return this.areas.flatMap(areaAvailability => {
            return areaAvailability.bookingSlots.flatMap(bookingSlotAvailability => {
                return bookingSlotAvailability.options.map(option => {
                    return option.eventId
                })
            })
        })
            .filter(eventId => eventId !== null)
            .filter((value, index, self) => {
                return self.indexOf(value) === index
            })
    }
}
