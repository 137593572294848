import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { Venue } from '../domain/Venue'
import { FormGroup } from '@angular/forms'
import { Voucher } from '../domain/Voucher'
import { SKU } from '../domain/SKU'
import { combineLatestWith, takeUntil } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { RichTextModalComponent } from '../rich-text-modal/rich-text-modal.component'

@Component({
    selector: 'app-voucher-selection',
    templateUrl: './voucher-selection.component.html',
})
export class VoucherSelectionComponent implements OnInit {

    @Input() venue!: Venue
    @Input() voucher!: Voucher
    @Input() form!: FormGroup
    @Output() checkoutSelected = new EventEmitter<void>()
    selectedSKU: SKU | null = null
    descriptionCharacterLimit = 500
    descriptionShowMore = false
    private modalService = inject(NgbModal)
    private onDestroy$ = new EventEmitter<void>()

    ngOnInit() {
        this.bindFormToVoucherSelectedSKU()
    }

    showPolicy() {
        const modal = this.modalService.open(RichTextModalComponent)
        modal.componentInstance.title = 'Terms and Conditions'
        modal.componentInstance.content = this.voucher.policy
    }

    goToCheckout() {
        this.checkoutSelected.emit()
    }

    private bindFormToVoucherSelectedSKU() {
        this.form.get('skuId')?.valueChanges
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe((skuId) => {
                this.selectedSKU = this.voucher.getSKUWithId(skuId)
            })
    }
}
