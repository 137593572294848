<div class="vstack gap-3">
    <div class="vstack gap-2">
        <div class="vstack gap-2">
            <h5 class="fw-semibold m-0 px-3">
                Reason for your visit
            </h5>
        </div>
        @if (hasReasonsOutsideIdealTimeRange) {
            <span class="small text-secondary px-3">
                {{ numberOfReasonsOutsideIdealTimeRange | i18nPlural : reasonPluralMapping}}
                available outside {{ form.get('idealTime')?.value | date : 'shortTime' }}
            </span>
            <div
                class="btn-group segmented-control flex-fill-equally"
                role="group"
            >
                <input
                    type="radio"
                    class="btn-check"
                    id="reasons-at-ideal-time"
                    autocomplete="off"
                    [checked]="!showAllReasons"
                >
                <label
                    class="btn btn-outline-primary btn-sm"
                    for="reasons-at-ideal-time"
                    data-testid="reasons-at-ideal-time"
                    (click)="toggleShowAllReasons()"
                >
                    Available at {{ form.get('idealTime')?.value | date : 'shortTime' }}
                </label>
                <input
                    type="radio"
                    class="btn-check"
                    id="all-reasons"
                    autocomplete="off"
                    [checked]="showAllReasons"
                >
                <label
                    class="btn btn-outline-primary btn-sm"
                    for="all-reasons"
                    data-testid="all-reasons"
                    (click)="toggleShowAllReasons()"
                >
                    Available Any Time
                </label>
            </div>
        }
    </div>
    <div class="vstack gap-2">
        @if (hasReasonsFittingPartySize) {
            @if (showAllReasons) {
                @for (reason of allReasons; track reason.id) {
                    <ng-container
                        *ngTemplateOutlet="reasonLabel; context: { reason: reason }"
                    >
                    </ng-container>
                }
            } @else {
                @for (reason of reasonsAtIdealTime; track reason.id) {
                    <ng-container
                        *ngTemplateOutlet="reasonLabel; context: { reason: reason }"
                    >
                    </ng-container>
                }
            }
        } @else {
            <app-no-availability
                [availability]="availability"
                [requiresDogFriendly]="form.get('requiresDogFriendly')?.value"
                [partySize]="form.get('partySize')?.value"
                [areaId]="form.get('selectedAreaId')?.value"
                [eventId]="form.get('selectedEventId')?.value"
            >
            </app-no-availability>
        }
    </div>
</div>

<ng-template
    #reasonLabel
    let-reason="reason"
>
    <app-reason-card
        [reason]="reason"
        [date]="form.get('idealTime')?.value"
        [isSelected]="form.get('selectedReasonId')?.value === reason.id"
        (reasonSelected)="reasonSelected.emit(reason)"
    >
    </app-reason-card>
</ng-template>
