import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Venue } from '../domain/Venue'
import { Voucher } from '../domain/Voucher'
import { SKU } from '../domain/SKU'
import { PaymentsService } from '../services/payments.service'
import { StripeElements } from '@stripe/stripe-js'
import { Sale } from '../domain/Sale'
import { environment } from '../../environments/environment'
import { RichTextModalComponent } from '../rich-text-modal/rich-text-modal.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-voucher-checkout',
    templateUrl: './voucher-checkout.component.html',
})
export class VoucherCheckoutComponent implements OnInit {

    @Input() venue!: Venue
    @Input() voucher!: Voucher
    @Input() form!: FormGroup
    @Output() paymentConfirmed = new EventEmitter<Sale>()
    selectedSKU: SKU | null = null
    stripeElements: StripeElements | null = null
    checkoutForm!: FormGroup
    isPaying = false
    privacyPolicyURL = environment.privacyPolicyURL
    recipientMessageToolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['clean'],
    ]
    private sale: Sale | null = null

    constructor(
        private fb: FormBuilder,
        private modalService: NgbModal,
        private paymentsService: PaymentsService
    ) { }

    ngOnInit() {
        this.checkoutForm = this.makeCheckoutForm()
        const skuId = this.form.get('skuId')?.value
        this.selectedSKU = this.voucher.getSKUWithId(skuId)
        this.mountSetUpPaymentDetailsElement(this.venue)
            .catch((error) => {
                console.error(error)
            })
    }

    showPolicy() {
        const modal = this.modalService.open(RichTextModalComponent)
        modal.componentInstance.title = 'Terms and Conditions'
        modal.componentInstance.content = this.voucher.policy
    }

    async pay() {
        this.checkoutForm.markAllAsTouched()
        this.checkoutForm.updateValueAndValidity()
        if (this.form.invalid) {
            return
        }
        if (this.stripeElements === null) {
            throw new Error('Stripe elements not set up')
        }
        const billingEmailAddress = this.checkoutForm.get('billingEmailAddress')?.value
        if (billingEmailAddress === null) {
            throw new Error('Billing email address not set up')
        }
        const shippingEmailAddress = this.checkoutForm.get('deliveryEmailAddress')?.value
        if (shippingEmailAddress === null) {
            throw new Error('Shipping email address not set up')
        }
        const recipientName = this.checkoutForm.get('recipientName')?.value
        const senderMessage = this.checkoutForm.get('senderMessage')?.value
        if (this.sale === null) {
            throw new Error('Sale not set up')
        }
        this.isPaying = true
        this.sale = await this.paymentsService.setSaleFulfilmentDetails(
            this.venue.id,
            this.sale.id,
            shippingEmailAddress,
            recipientName,
            senderMessage
        )
        const payment = await this.paymentsService.confirmPayment(
            this.stripeElements,
            billingEmailAddress
        )
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                this.isPaying = false
            })
        if (payment === undefined) {
            return
        }
        this.paymentConfirmed.emit(this.sale)
    }

    private async mountSetUpPaymentDetailsElement(
        venue: Venue
    ) {
        const paymentsAccountId = venue.paymentsAccount?.id
        if (!paymentsAccountId) {
            throw new Error('Payments account ID not set up')
        }
        if (this.selectedSKU === null) {
            throw new Error('Selected SKU not set up')
        }
        this.paymentsService.setUpForAccount(paymentsAccountId)
        const response = await this.paymentsService.makeSalePaymentIntent(
            venue.id,
            this.voucher.id,
            this.selectedSKU.id
        )
        this.sale = response.sale
        this.stripeElements = await this.paymentsService.createStripeElements(
            response.paymentIntent.clientSecret
        )
        const element = this.stripeElements.create('payment')
        element.mount('#payment-element')
    }

    private makeCheckoutForm() {
        return this.fb.group({
            billingEmailAddress: [null, [Validators.required, Validators.email]],
            deliveryEmailAddress: [null, [Validators.required, Validators.email]],
            recipientName: [null],
            senderMessage: [null],
            agreedToTerms: [false, [Validators.requiredTrue]],
        })
    }
}
