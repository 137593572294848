import { Availability } from '../domain/Availability'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingRequest } from '../domain/BookingRequest'
import { BookingRequestFactory } from '../domain/BookingRequestFactory'
import { BookingSlot } from '../domain/BookingSlot'
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core'
import { ContextService } from '../services/ContextService'
import { FormGroup, FormGroupDirective } from '@angular/forms'
import { FormStep } from '../booking-form/booking-form.component'
import { HttpErrorResponse } from '@angular/common/http'
import { NGBDateTimeAdapter } from '../utilities/NgbDateTimeAdapter'
import { NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap'
import { PaymentsService } from '../services/payments.service'
import { RouterContextService } from '../services/RouterContextService'
import { StripeElements } from '@stripe/stripe-js'
import { Subject } from 'rxjs'
import { Venue } from '../domain/Venue'
import DineroFactory, { Currency, Dinero } from 'dinero.js'
import { LoggingService } from '../services/logging.service'

@Component({
    selector: 'app-payment-details-form',
    templateUrl: './payment-details-form.component.html',
    styleUrls: ['./payment-details-form.component.sass'],
    providers: [{
        provide: ContextService,
        useClass: RouterContextService,
    }],
})
export class PaymentDetailsFormComponent implements OnInit, OnDestroy {

    @Input() showTitle = false
    @Input() showBackButton = false
    @Input() venue?: Venue
    @Input() bookingDetails?: BookingDetails
    @Output() backSelected = new EventEmitter<void>()
    @Output() paymentStarted = new EventEmitter<void>()
    @Output() bookSelected = new EventEmitter<void>()
    form!: FormGroup
    cancellationChargeAmount!: Dinero
    cancellationChargeIsPerCover!: boolean
    cancellationChargeCutOffHours: number | null = null
    paymentDetailsFormError: string | null = null
    bookingSlotUnavailableWarning: string | null = null
    hoursPluralMapping = {
        '=0': '# hours',
        '=1': '# hour',
        other: '# hours',
    }
    isSaving = false
    cardDetailsAreComplete = false
    private paymentElements: StripeElements | null = null
    private ngbDateAdapter = new NgbDateNativeAdapter()
    private ngbTimeAdapter = new NGBDateTimeAdapter()
    private onDestroy$ = new Subject<void>()

    constructor(
        private rootFormGroup: FormGroupDirective,
        private contextService: ContextService,
        private loggingService: LoggingService,
        private paymentsService: PaymentsService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        let formName = FormStep[FormStep.PaymentDetails]
        this.form = this.rootFormGroup.control.get(formName) as FormGroup
        this.setUpChargeDetails()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    onBackSelected() {
        this.backSelected.emit()
    }

    async onBookSelected() {
        this.isSaving = true
        this.paymentStarted.emit()
        await this.savePaymentDetails()
            .catch(error => {
                this.loggingService.noticeError(error)
                if (error instanceof HttpErrorResponse && error.status === 404) {
                    this.bookingSlotUnavailableWarning = 'Sorry, this booking slot is no longer available. Please select another time.'
                } else {
                    const defaultErrorMessage = 'There was an error saving your payment details. ' +
                        'Please refresh the page and try again.'
                    this.paymentDetailsFormError = error.message ?? defaultErrorMessage
                }
                this.isSaving = false
                throw error
            })
            .then(() => {
                this.bookSelected.emit()
            })
            .finally(() => {
                this.isSaving = false
            })
    }

    clearFormErrors() {
        this.paymentDetailsFormError = null
        this.bookingSlotUnavailableWarning = null
    }

    private setUpChargeDetails() {
        if (this.venue !== undefined && this.bookingDetails !== undefined) {
            this.setUpChargeDetailsFromBookingDetails(this.venue, this.bookingDetails)
        } else {
            this.setUpChargeDetailsFromForm()
        }
    }

    private setUpChargeDetailsFromBookingDetails(venue: Venue, bookingDetails: BookingDetails) {
        const cancellationChargeAmount = bookingDetails.cancellationChargeAmount
        if (cancellationChargeAmount === null) {
            return
        }
        this.cancellationChargeAmount = DineroFactory({
            amount: cancellationChargeAmount,
            currency: venue.currencyCode as Currency,
        })
        this.cancellationChargeIsPerCover = false
        this.cancellationChargeCutOffHours = venue.cancellationChargeCutOffHours
        this.mountPendingPaymentSetupPaymentDetailsElement(venue, bookingDetails)
            .catch(error => {
                if (error instanceof HttpErrorResponse && error.status === 404) {
                    this.bookingSlotUnavailableWarning = 'Sorry, this booking slot is no longer available. Please select another time.'
                } else {
                    this.paymentDetailsFormError =
                        'There was an error setting up your booking. ' +
                        'Please refresh the page and try again.'
                }
            })
    }

    private setUpChargeDetailsFromForm() {
        const bookingSlotForm = this.rootFormGroup.control.get('BookingSlot')
        let bookingSlot: BookingSlot = bookingSlotForm?.get('bookingSlot')?.value
        if (bookingSlot === undefined) {
            const ngbDate = bookingSlotForm?.get('date')?.value
            const ngbTime = bookingSlotForm?.get('time')?.value
            const date = this.ngbDateAdapter.toModel(ngbDate)!
            const time = this.ngbTimeAdapter.toModel(ngbTime)!
            const dateTime = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                time.getHours(),
                time.getMinutes()
            )
            bookingSlot = new BookingSlot(dateTime)
        }
        if (bookingSlot === null) {
            return
        }
        const availabilityControl = bookingSlotForm?.get('availability')
        const availability = availabilityControl?.value as Availability | null
        if (availability === null) {
            return
        }
        const venue: Venue = this.venue
            ?? availability?.venue
        if (venue === null) {
            return
        }
        const areaId: string = bookingSlotForm?.get('selectedAreaId')?.value as string
        if (areaId === null) {
            return
        }
        const bookingSlotOption = availability
            .reservableOptionWithConfiguration(
                bookingSlotForm?.get('selectedAreaId')?.value,
                bookingSlotForm?.get('partySize')?.value,
                bookingSlotForm?.get('selectedReasonId')?.value,
                bookingSlotForm?.get('selectedEventId')?.value,
                bookingSlot.dateTime
            )
        if (bookingSlotOption === null) {
            return
        }
        this.cancellationChargeAmount = DineroFactory({
            amount: bookingSlotOption.cancellationChargeAmount!,
            currency: venue.currencyCode as Currency,
        })
        this.cancellationChargeIsPerCover = bookingSlotOption.cancellationChargeIsPerCover
        this.cancellationChargeCutOffHours = venue.cancellationChargeCutOffHoursForConfiguration(
            bookingSlotForm?.get('selectedEventId')?.value
        )
        const factory = new BookingRequestFactory(this.rootFormGroup.control)
        const bookingRequest = factory.make()
        this.mountSetUpPaymentDetailsElement(venue, areaId, bookingRequest)
            .catch(error => {
                if (error instanceof HttpErrorResponse && error.status === 404) {
                    this.bookingSlotUnavailableWarning = 'Sorry, this booking slot is no longer available. Please select another time.'
                } else {
                    this.paymentDetailsFormError =
                        'There was an error setting up your booking. ' +
                        'Please refresh the page and try again.'
                }
            })
    }

    private async mountSetUpPaymentDetailsElement(
        venue: Venue,
        areaId: string,
        bookingRequest: BookingRequest
    ) {
        const paymentsAccountId = venue.paymentsAccount?.id
        if (!paymentsAccountId) {
            throw new Error('Payments account ID not set up')
        }
        this.paymentsService.setUpForAccount(paymentsAccountId)
        const payment = await this.paymentsService.makeSetupIntentClientSecret(
            venue.organisationId,
            venue.businessId,
            venue.id,
            areaId,
            bookingRequest
        )
        this.paymentElements = await this.paymentsService.createStripeElements(payment.clientSecret)
        const paymentIntent = this.paymentElements.create('payment')
        paymentIntent.mount('#payment-intent')
        paymentIntent.on('change', event => {
            this.cardDetailsAreComplete = event.complete
            this.changeDetectorRef.detectChanges()
        })
    }

    private async mountPendingPaymentSetupPaymentDetailsElement(
        venue: Venue,
        bookingDetails: BookingDetails
    ) {
        const paymentsAccountId = venue.paymentsAccount?.id
        if (!paymentsAccountId) {
            throw new Error('Payments account ID not set up')
        }
        this.paymentsService.setUpForAccount(paymentsAccountId)
        const payment = await this.paymentsService.makePendingPaymentSetupIntentClientSecret(
            venue.organisationId,
            venue.businessId,
            bookingDetails
        )
        this.paymentElements = await this.paymentsService.createStripeElements(payment.clientSecret)
        const paymentIntent = this.paymentElements.create('payment')
        paymentIntent.mount('#payment-intent')
        paymentIntent.on('change', event => {
            this.cardDetailsAreComplete = event.complete
            this.changeDetectorRef.detectChanges()
        })
    }

    private async savePaymentDetails() {
        const elements = this.paymentElements
        if (elements === null) {
            throw new Error('Payment elements not set up')
        }
        const setupIntentId = await this.paymentsService.savePaymentDetails(elements)
        this.form.patchValue({ 'paymentMethodId': setupIntentId })
    }
}
