@if (isPaying) {
    <app-spinner-overlay
        [showSpinner]="isPaying"
    >
    </app-spinner-overlay>
}
<div
    class="row g-0"
    [formGroup]="form"
>
    <div class="col-md-7">
        <div class="vstack gap-3 px-4 py-3 pb-4 px-lg-5 py-lg-4 mb-auto">
            <ng-container
                *ngTemplateOutlet="shippingDetails"
            >
            </ng-container>
            <ng-container
                *ngTemplateOutlet="paymentDetails"
            >
            </ng-container>
        </div>
    </div>
    <div class="col-md-5 bg-light">
        <div class="px-4 py-3 pb-4 px-lg-5 py-lg-4 pb-lg-5">
            <ng-container
                *ngTemplateOutlet="productSummary"
            >
            </ng-container>
        </div>
    </div>
</div>

<ng-template
    #productSummary
>
    <div class="vstack gap-2 flex-grow-0">
<!--        <div class="card">-->
<!--            <div class="card-body">-->
                <div class="vstack gap-1">
                    <h6 class="fw-semibold m-0">
                        Order Summary
                    </h6>
                    <hr class="border-dark-subtle my-2"/>
                    <div class="hstack gap-2 justify-content-between">
                        <span>
                            {{ voucher.displayName }} x1
                        </span>
                        @if (selectedSKU) {
                            <span>
                                {{ null | currencyUnit : selectedSKU.price : 'GBP' }}
                            </span>
                        }
                    </div>
                    <hr class="border-dark-subtle my-2"/>
                    <div class="vstack gap-1">
                        <div class="hstack justify-content-between">
                            <span>
                                Subtotal
                            </span>
                            <span>
                                {{ null | currencyUnit : selectedSKU?.price : 'GBP' }}
                            </span>
                        </div>
                        <div class="hstack justify-content-between">
                            <span class="fw-semibold">
                                Total
                            </span>
                            <span class="fw-semibold">
                                {{ null | currencyUnit : selectedSKU?.price : 'GBP' }}
                            </span>
                        </div>
                    </div>
                </div>
                @if (voucher.policy && voucher.policy.length > 0) {
                    <hr class="border-dark-subtle my-2"/>
                    <div class="vstack gap-2 flex-grow-0">
                        <span class="text-muted small">
                            By placing an order for this voucher, you agree to
                            {{ venue.displayName }}'s
                            Terms & Conditions:
                        </span>
                        <ul class="list-group">
                            <a
                                class="list-group-item list-group-item-action hstack justify-content-between"
                                role="button"
                                (click)="showPolicy()"
                            >
                                <span class="fw-semibold">
                                    Terms & Conditions
                                </span>
                                <img
                                    ngSrc="assets/right/normal.svg"
                                    width="16"
                                    height="16"
                                    alt="Terms & Conditions"
                                >
                            </a>
                        </ul>
                    </div>
                }
<!--            </div>-->
<!--        </div>-->
    </div>
</ng-template>

<ng-template
    #shippingDetails
>
    <div
        class="flex-grow-0"
        [formGroup]="checkoutForm"
    >
        <div class="vstack gap-2">
            <div class="vstack">
                <h6 class="fw-semibold m-0">
                    Delivery Details
                </h6>
            </div>
            <div class="form-group">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group has-validation gap-2">
                            <label
                                for="email"
                                class="form-label"
                            >
                                Recipient Email Address
                            </label>
                        </div>
                        <input
                            type="email"
                            class="form-control"
                            id="email"
                            formControlName="deliveryEmailAddress"
                            [ngClass]="{
                                'is-invalid': checkoutForm.get('deliveryEmailAddress')!!.invalid && checkoutForm.get('deliveryEmailAddress')!!.touched
                            }"
                        >
                    </div>
                    <div class="col-md-6">
                        <div class="input-group has-validation gap-2">
                            <label
                                for="recipientName"
                                class="form-label"
                            >
                                Recipient Name (Optional)
                            </label>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            id="recipientName"
                            formControlName="recipientName"
                            [ngClass]="{
                                'is-invalid': checkoutForm.get('recipientName')!!.invalid && checkoutForm.get('recipientName')!!.touched
                            }"
                        >
                    </div>
                    <div class="col-12">
                        <quill-editor
                            class="mt-2 w-100 border rounded"
                            formControlName="senderMessage"
                            theme="bubble"
                            placeholder="Add a personal message for the recipient (Optional)"
                            [modules]="{ toolbar: recipientMessageToolbarOptions }"
                            [trimOnValidation]="true"
                        >
                        </quill-editor>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template
    #paymentDetails
>
    <div
        class="vstack gap-2"
        [formGroup]="checkoutForm"
    >
        <h6 class="fw-semibold m-0">
            Payment Details
        </h6>
        <div class="vstack gap-2">
            <div class="form-group">
                <div class="input-group has-validation gap-2">
                    <label
                        for="billingEmailAddress"
                        class="form-label text-muted-subtle mb-1"
                    >
                        Billing Email Address
                    </label>
                </div>
                <input
                    type="email"
                    class="form-control border-light-subtle"
                    id="billingEmailAddress"
                    formControlName="billingEmailAddress"
                    [ngClass]="{
                        'is-invalid': checkoutForm.get('billingEmailAddress')!!.invalid && checkoutForm.get('billingEmailAddress')!!.touched
                    }"
                />
            </div>
            <div
                id="payment-element"
                [style.min-height.px]="230"
            >
            </div>
            <div class="form-check hstack gap-2 align-items-center mt-1">
                <input
                    class="form-check-input"
                    type="checkbox"
                    id="agreedToTerms"
                    formControlName="agreedToTerms"
                    data-testid="agreed-to-terms"
                    [ngClass]="{
                    'is-invalid': checkoutForm.get('agreedToTerms')!!.invalid && checkoutForm.get('agreedToTerms')!!.touched
                }"
                >
                <label for="agreedToTerms">
                    I have read and accept the TableSense
                    <a class="text-body" [href]="privacyPolicyURL" target="_blank">Privacy Policy</a>
                    agreement
                    <span
                        *ngIf="checkoutForm.get('agreedToTerms')!!.invalid && checkoutForm.get('agreedToTerms')!!.touched"
                        class="text-danger small"
                    >
                    You need to accept the Privacy Policy to continue with the purchase
                </span>
                </label>
            </div>
        </div>
        <button
            class="btn btn-primary"
            appVenueColour
            [primaryColour]="venue.primaryColour"
            (click)="pay()"
        >
            Pay {{ null | currencyUnit : selectedSKU?.price : 'GBP' }}
        </button>
    </div>
</ng-template>
