export type PaymentGateway = 'stripe' | 'breathepay'

export class PaymentsAccount {
    constructor(
        public id: string,
        public gateway: PaymentGateway
    ) { }

    usesStripe(): boolean {
        return this.gateway === 'stripe'
    }

    usesBreathePay(): boolean {
        return this.gateway === 'breathepay'
    }
}
