import { AccessibilityFormComponent } from './accessibility-form/accessibility-form.component'
import { AddressPipe } from './pipes/address.pipe'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { LOCALE_ID, NgModule } from '@angular/core'
import { AreaNamePipe } from './pipes/area-name.pipe'
import { BookingDetailsComponent } from './booking-details/booking-details.component'
import { BookingDetailsFormComponent } from './booking-details-form/booking-details-form.component'
import { BookingFeedbackComponent } from './booking-feedback/booking-feedback.component'
import {
    BookingFeedbackConfirmationComponent,
} from './booking-feedback-confirmation/booking-feedback-confirmation.component'
import {
    BookingFeedbackFormComponent,
} from './booking-feedback-form/booking-feedback-form.component'
import { BookingFormComponent } from './booking-form/booking-form.component'
import {
    BookingModalComponent,
} from './booking-modal/booking-modal.component'
import { BookingModalLauncherComponent } from './modal-launcher/booking-modal-launcher.component'
import { BookingPolicyStepComponent } from './booking-policy-step/booking-policy-step.component'
import { BookingReminderComponent } from './booking-reminder/booking-reminder.component'
import { BookingSlotComponent } from './booking-slot/booking-slot.component'
import {
    BookingSlotSelectionComponent,
} from './booking-slot-selection/booking-slot-selection.component'
import { BookingSummaryComponent } from './booking-summary/booking-summary.component'
import { BrowserModule } from '@angular/platform-browser'
import { CapitaliseOnBlurDirective } from './directives/capitalise-on-blur.directive'
import { ConfirmCancellationComponent } from './confirm-cancellation/confirm-cancellation.component'
import { ConfirmCardComponent } from './confirm-payment/confirm-card.component'
import { ConfirmationComponent } from './confirmation/confirmation.component'
import { ContextService } from './services/ContextService'
import { CurrencyPipe, DatePipe, I18nPluralPipe, NgOptimizedImage, registerLocaleData } from '@angular/common'
import { CurrencyUnitPipe } from './pipes/currency-unit.pipe'
import { DayOfYearInputComponent } from './day-of-year-input/day-of-year-input.component'
import { DepositFormComponent } from './deposit-form/deposit-form.component'
import { DurationPipe } from './pipes/duration.pipe'
import { ErrorComponent } from './error/error.component'
import { EventNamePipe } from './pipes/event-name.pipe'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import gbLocale from '@angular/common/locales/en-GB'
import { LoadingComponent } from './loading/loading.component'
import { ModalBookingDetailsComponent } from './modal-booking-details/modal-booking-details.component'
import { ModalBookingFormComponent } from './modal-booking-form/modal-booking-form.component'
import { ModifyBookingComponent } from './modify-booking/modify-booking.component'
import { NgbDatePipe } from './pipes/ngb-date.pipe'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgbTimePipe } from './pipes/ngb-time.pipe'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg'
import { NgxPaginationModule } from 'ngx-pagination'
import { NoAvailabilityComponent } from './no-availability/no-availability.component'
import { PaymentDetailsFormComponent } from './payment-details-form/payment-details-form.component'
import { RatingNamePipe } from './pipes/rating-name.pipe'
import { ReasonNamePipe } from './pipes/reason-name.pipe'
import { ReasonSelectionComponent } from './modal-booking-form/reason-selection/reason-selection.component'
import { ReasonTimePipe } from './pipes/reason-time.pipe'
import { RelativeDatePipe } from './pipes/relative-date.pipe'
import { RouterContextService } from './services/RouterContextService'
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators'
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component'
import { ThumborPipe } from './pipes/thumbor.pipe'
import { VenueColourDirective } from './directives/venue-colour.directive'
import { WithLoadingPipe } from './pipes/with-loading.pipe'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { EventCardComponent } from './modal-booking-form/event-card/event-card.component';
import { VoucherModalComponent } from './voucher-modal/voucher-modal.component';
import { VoucherModalLauncherComponent } from './modal-launcher/voucher-modal-launcher.component';
import { VoucherCheckoutComponent } from './voucher-checkout/voucher-checkout.component';
import { VoucherSelectionComponent } from './voucher-selection/voucher-selection.component';
import { VoucherPaymentConfirmationComponent } from './voucher-payment-confirmation/voucher-payment-confirmation.component'
import { ReasonCardComponent } from './modal-booking-form/reason-selection/reason-card/reason-card.component'
import { VoucherCardComponent } from './voucher-card/voucher-card.component'
import { RichTextModalComponent } from './rich-text-modal/rich-text-modal.component'
import { QuillModule } from 'ngx-quill'

registerLocaleData(gbLocale)

@NgModule({ declarations: [
    AccessibilityFormComponent,
    AddressPipe,
    AppComponent,
    AreaNamePipe,
    BookingDetailsComponent,
    BookingDetailsFormComponent,
    BookingFeedbackComponent,
    BookingFeedbackConfirmationComponent,
    BookingFeedbackFormComponent,
    BookingFormComponent,
    BookingModalComponent,
    BookingModalLauncherComponent,
    BookingPolicyStepComponent,
    BookingReminderComponent,
    BookingSlotComponent,
    BookingSlotSelectionComponent,
    BookingSummaryComponent,
    ConfirmationComponent,
    ConfirmCancellationComponent,
    ConfirmCardComponent,
    CurrencyUnitPipe,
    DayOfYearInputComponent,
    DepositFormComponent,
    DurationPipe,
    DurationPipe,
    ErrorComponent,
    EventNamePipe,
    LoadingComponent,
    ModalBookingDetailsComponent,
    ModalBookingDetailsComponent,
    ModalBookingFormComponent,
    ModifyBookingComponent,
    NgbDatePipe,
    NgbTimePipe,
    NoAvailabilityComponent,
    PaymentDetailsFormComponent,
    RatingNamePipe,
    ReasonNamePipe,
    ReasonSelectionComponent,
    RelativeDatePipe,
    SpinnerOverlayComponent,
    ThumborPipe,
    VenueColourDirective,
    WithLoadingPipe,
    EventCardComponent,
    VoucherCardComponent,
    VoucherModalComponent,
    VoucherModalLauncherComponent,
    VoucherCheckoutComponent,
    VoucherSelectionComponent,
    VoucherPaymentConfirmationComponent,
    ReasonCardComponent,
    RichTextModalComponent,
],
bootstrap: [AppComponent],
exports: [
    CurrencyUnitPipe,
    LoadingComponent,
    NgbDatePipe,
    RatingNamePipe,
],
    imports: [
        AppRoutingModule,
        BrowserModule,
        CapitaliseOnBlurDirective,
        FormsModule,
        NgbModule,
        NgOptimizedImage,
        NgxIntlTelInputModule,
        NgxPaginationModule,
        QuillModule.forRoot(),
        ReactiveFormsModule,
        ReasonTimePipe,
        RxReactiveFormsModule,
    ],
providers: [
    {
        provide: ContextService,
        useClass: RouterContextService,
    },
    {
        provide: LOCALE_ID,
        useValue: 'en-GB',
    },
    CurrencyPipe,
    DatePipe,
    DurationPipe,
    I18nPluralPipe,
    NgbDatePipe,
    NgbTimePipe,
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
] })
export class AppModule {

    constructor() { }
}
