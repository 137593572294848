@let bookingPolicy = venue.bookingPolicy;
@if (bookingPolicy) {
    <div class="vstack gap-2 h-100">
        <quill-view
            [content]="bookingPolicy"
            style="max-height: 65vh; overflow: auto;"
        >
        </quill-view>
        <button
            type="button"
            class="btn btn-primary mb-0"
            appVenueColour
            [primaryColour]="venue.primaryColour"
            (click)="acceptedPolicy.emit()"
        >
            Accept
        </button>
    </div>
}
