export class Event {

    constructor(
        public id: string,
        public displayName: string,
        public displayOrder: number,
        public description: string,
        public imageId: string | null,
        public cancellationChargeAmount: number | null,
        public cancellationChargePartyAmount: number | null,
        public cancellationChargeCutOffHours: number | null,
        public depositAmount: number | null,
        public depositRefundCutOffDays: number | null,
        public isExclusive: boolean,
        public questionIds: string[],
        public notReservableOnline: boolean,
        public notReservableOnlineMessage: string | null,
        public recurrence: EventRecurrence,
        public partySizeRestriction: number[] | null,
        public partySizeRestrictedMessage: string | null
    ) { }

    isPartySizeAllowed(partySize: number): boolean {
        if (this.partySizeRestriction === null) {
            return true
        }
        return this.partySizeRestriction.includes(partySize)
    }
}

export class EventRecurrence {

    constructor(
        public startDates: Date[],
        public endDate: Date | null
    ) { }
}
