import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Venue } from '../domain/Venue'

@Component({
    selector: 'app-booking-policy-step',
    templateUrl: 'booking-policy-step.component.html',
})
export class BookingPolicyStepComponent {

    @Input() venue!: Venue
    @Output() acceptedPolicy = new EventEmitter<void>()
}
