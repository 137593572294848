import { Availability } from '../domain/Availability'
import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-no-availability',
    templateUrl: './no-availability.component.html',
})
export class NoAvailabilityComponent {

    @Input() availability!: Availability
    @Input() requiresDogFriendly!: boolean | null
    @Input() partySize!: number
    @Input() areaId!: string
    @Input() eventId!: string | null
}
