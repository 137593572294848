<div class="row">
    <div class="col-12 col-md-8 offset-md-2">
        <div class="p-3 vstack gap-3">
            <div class="vstack gap-1">
                <h5 class="m-0">
                    Your Order
                </h5>
                <span class="text-muted">
                    Thanks you for shopping with us.
                    <br/>
                    We've received your order and will be processing it shortly.
                </span>
            </div>
            @if (sku !== null) {
                <div class="px-4 py-3 rounded bg-light vstack gap-1">
                    <div class="vstack gap-2 justify-content-between">
                        @for (lineItem of sale.order.lineItems; track lineItem.id) {
                            <div class="vstack gap-2">
                                <app-voucher-card
                                    class="d-flex align-self-center"
                                    style="min-width: 280px;"
                                    [venue]="venue"
                                    [voucher]="voucher"
                                    [sku]="sku"
                                >
                                </app-voucher-card>
                                <div class="vstack gap-1 align-self-center">
                                    <span class="fw-semibold">
                                        {{ voucher.displayName }}
                                    </span>
                                    @if (voucher.description) {
                                        <span class="text-muted">
                                            {{ voucher.description | slice : 0:100 }}@if (voucher.description.length > 10) {...}
                                        </span>
                                    }
                                    <span class="fw-semibold">
                                        {{ null | currencyUnit : lineItem.amount : sale.order.currencyCode }}
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <hr class="m-0 border-dark-subtle"/>
            }
            <div class="">
                <div class="vstack gap-3">
                    <div class="vstack gap-1">
                        <span class="fw-semibold">
                            Delivery Details
                        </span>
                        @if (sale.deliveryDetails?.emailAddress !== null) {
                            @let email = sale.deliveryDetails?.emailAddress;
                            <span class="hstack gap-2 text-muted">
                            <img
                                ngSrc="assets/people/normal.svg"
                                width="16"
                                height="16"
                            />
                                {{ email }}
                            </span>
                        }
                    </div>
                    <hr class="m-0 border-dark-subtle"/>
                    <div class="vstack gap-1">
                        <div class="hstack justify-content-between">
                    <span>
                        Subtotal
                    </span>
                            <span>
                        {{ null | currencyUnit : sale.order.total() : sale.order.currencyCode }}
                    </span>
                        </div>
                        <div class="hstack justify-content-between">
                    <span class="fw-semibold">
                        Total
                    </span>
                            <span class="fw-semibold">
                        {{ null | currencyUnit : sale.order.total() : sale.order.currencyCode }}
                    </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="m-0 border-dark-subtle"/>
        </div>
    </div>
</div>
