import { Address } from './Address'
import { BookingStatusType } from './BookingStatus'
import DineroFactory, { Currency, Dinero } from 'dinero.js'

export enum BookingType {
    Standard,
    Event,
}

export class BookingDetails {
    constructor(
        public bookingId: string,
        public currencyCode: string | null,
        public venueId: string,
        public venueName: string,
        public venueAddress: Address,
        public venueDiningInformation: string | null,
        public venuePhoneNumber: string,
        public bookingName: string,
        public partySize: number,
        public durationMinutes: number,
        public dateTime: Date,
        public statusType: BookingStatusType,
        public cancellationChargeAmount: number | null,
        public cancellationChargeCutOffTime: Date | null,
        public areaId: string,
        public areaName: string | null,
        public reasonId: string | null,
        public reasonName: string | null,
        public reasonDiningInformation: string | null,
        public eventId: string | null,
        public eventName: string | null,
        public eventImageUrl: string | null,
        public depositAmount: number | null,
        public depositCurrencyCode: string | null,
        public depositDatePaid: Date | null,
        public depositDateRefunded: Date | null,
        public depositRefundCutOffTime: Date | null,
        public depositRefundPolicy: string | null,
        public requiresWheelchairAccess: boolean,
        public requiresDogFriendly: boolean,
        public modifyBookingPath: string | null,
        public pendingPaymentExpiryDateTime: Date | null
    ) { }

    public get cancellationCharge(): Dinero | null {
        if (this.cancellationChargeAmount === null) {
            return null
        }
        if (this.currencyCode === null) {
            return null
        }
        return DineroFactory({
            amount: this.cancellationChargeAmount,
            currency: this.currencyCode as Currency,
        })
    }

    public get depositCharge(): Dinero | null {
        if (this.depositAmount === null) {
            return null
        }
        if (this.depositCurrencyCode === null) {
            return null
        }
        return DineroFactory({
            amount: this.depositAmount,
            currency: this.depositCurrencyCode as Currency,
        })
    }

    public get endTime(): Date {
        return new Date(this.dateTime.getTime() + this.durationMinutes * 60 * 1000)
    }

    public get isDateRestricted() {
        if (this.modifyBookingPath === null) {
            return false
        }
        const url = new URL(this.modifyBookingPath, window.location.origin)
        const dateRestricted = url.searchParams.get('dateRestricted')
        return dateRestricted === 'true'
    }
}
