import { ActivatedRoute } from '@angular/router'
import { ApplicationRef, Component, OnDestroy, OnInit } from '@angular/core'
import { first, Subject, takeUntil } from 'rxjs'
import { LoggingService } from './services/logging.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {

    private onDestroy$ = new Subject<void>()

    constructor(
        private appRef: ApplicationRef,
        private loggingService: LoggingService,
        private route: ActivatedRoute
    ) {
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable))
        appIsStable$.subscribe(() => this.loggingService.setUp())
    }

    ngOnInit() {
        this.bindScaleQueryParameterToBodyTransform()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    private bindScaleQueryParameterToBodyTransform() {
        this.route.queryParamMap
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(queryParamMap => {
                const scale = queryParamMap.get('scale')
                if (!scale) {
                    return
                }
                document.body.style.transform = `scale(${scale})`
            })
    }
}
