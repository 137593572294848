<h4
    *ngIf="options.length === 0"
    class="d-flex h-100 justify-content-center align-items-center"
>
    <ng-container [ngTemplateOutlet]="emptyState">
    </ng-container>
</h4>
<ng-container
    *ngIf="options.length > 0"
    [ngTemplateOutlet]="content"
>
</ng-container>

<ng-template #emptyState>
    <span class="text-muted">
        No times available
    </span>
</ng-template>

<ng-template #content>
    <div class="vstack gap-2">
        <div class="vstack">
            <div class="flex-grow-1 d-grid three-column-grid-sm-two-column-grid">
                <label
                    *ngFor="let option of options | paginate: pageConfig"
                    class="btn btn-outline-secondary p-2 d-flex flex-column justify-content-center align-items-center"
                    [class.disabled]="!option.isReservable"
                    data-testid="time-option"
                >
                    <div class="m-1 hstack gap-2">
                        <input
                            type="radio"
                            [value]="option.start"
                            (change)="selectOption(option)"
                            class="form-check-input m-0"
                            role="button"
                        >
                        <div class="vstack align-items-start">
                            <span class="fw-semibold">
                                {{ option.start | ngbTime | date: 'shortTime' }}
                            </span>
                            <span
                                *ngIf="!option.isReservable"
                                class="small"
                            >
                                Unavailable
                            </span>
                        </div>
                    </div>
                </label>
            </div>
        </div>
        <ng-container
            *ngTemplateOutlet="paginationToolbar"
        >
        </ng-container>
    </div>
</ng-template>


<ng-template #paginationToolbar>
    <div
        *ngIf="pageConfig.itemsPerPage < pageConfig.totalItems"
        class="bg-white mt-auto"
    >
        <hr class="m-0"/>
        <div
            class="hstack gap-2 justify-content-between"
            pagination-template
            #p="paginationApi"
            (pageChange)="pageConfig.currentPage = $event"
        >
            <button
                class="btn btn-sm btn-outline-secondary"
                data-testid="earlier-booking-slot"
                [class.disabled]="p.isFirstPage()"
                [disabled]="p.isFirstPage()"
                (click)="p.previous()"
            >
                <i class="bi bi-chevron-left"></i>
                Earlier
            </button>
            <div class="d-none d-sm-flex hstack flex-grow-1 flex-wrap justify-content-center">
                <div
                    *ngFor="let page of p.pages"
                    [class.current]="p.getCurrent() === page.value"
                >
                    <a
                        *ngIf="p.getCurrent() !== page.value"
                        role="button"
                        class="p-2 text-decoration-none"
                        (click)="p.setCurrent(page.value)"
                    >
                        <span class="text-secondary">
                            {{ page.label }}
                        </span>
                    </a>
                    <div
                        *ngIf="p.getCurrent() === page.value"
                        class="p-2"
                    >
                        <span class="fw-semibold">
                            {{ page.label }}
                        </span>
                    </div>
                </div>
            </div>
            <button
                class="btn btn-sm btn-outline-secondary"
                [class.disabled]="p.isLastPage()"
                (click)="p.next()"
            >
                Later
                <i class="bi bi-chevron-right"></i>
            </button>
        </div>
    </div>
</ng-template>
