<div
    *ngIf="viewModel | async as viewModel"
    class="mx-auto vstack gap-3"
>
    <div class="mt-2 px-3 px-sm-5 py-sm-1 text-center">
        <ng-container
            *ngIf="viewModel.showCancellationStatus"
        >
            <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                <h2 class="m-0 text-success">
                    <i class="bi bi-check-circle"></i>
                </h2>
                <h3 class="m-0 fw-semibold">Booking Cancelled</h3>
            </div>
        </ng-container>
        <ng-container
            *ngIf="viewModel.showBookingConfirmedStatus"
        >
            <div
                class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center"
                data-testid="booking-confirmed-status"
            >
                <h2 class="m-0 text-success">
                    <i class="bi bi-check-circle"></i>
                </h2>
                <h3 class="m-0 fw-semibold">Booking Confirmed</h3>
            </div>
        </ng-container>
        <div
            *ngIf="viewModel.showBookingRequestedStatus"
            class="vstack gap-2"
        >
            <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                <img
                    class="d-block d-sm-none"
                    ngSrc="assets/clock/small_orange.svg"
                    height="24"
                    width="24"
                    alt="Requested"
                >
                <img
                    class="d-none d-sm-block"
                    ngSrc="assets/clock/large_orange.svg"
                    height="48"
                    width="48"
                    alt="Requested"
                >
                <h3 class="m-0 fw-semibold">Booking Requested</h3>
            </div>
            <span class="text-muted">
                We will contact you by email shortly to confirm your booking.
            </span>
        </div>
        @if (viewModel.showBookingRejectedStatus) {
            <div class="vstack gap-2">
                <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                    <h2 class="m-0 text-danger">
                        <i class="bi bi-x-circle"></i>
                    </h2>
                    <h3 class="m-0 fw-semibold">Booking Request Declined</h3>
                </div>
                <span class="text-muted">
                    We're sorry to inform you that your booking request has not been accepted.
                </span>
            </div>
        }
        <div
            *ngIf="viewModel.showPendingDepositStatus"
            class="vstack gap-2"
        >
            <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                <img
                    class="d-block d-sm-none"
                    ngSrc="assets/clock/small_orange.svg"
                    height="24"
                    width="24"
                    alt="Requested"
                >
                <img
                    class="d-none d-sm-block"
                    ngSrc="assets/clock/large_orange.svg"
                    height="48"
                    width="48"
                    alt="Requested"
                >
                <h3 class="m-0 fw-semibold">Pending Payment</h3>
            </div>
            <span
                *ngIf="viewModel.details.depositCharge"
                class="text-muted"
            >
                We require a deposit of
                <span class="fw-bold">
                    {{ viewModel.details.depositCharge.toFormat() }}
                </span>
                to book your table.
                @if (viewModel.details.pendingPaymentExpiryDateTime) {
                    <br/>
                    Please confirm by
                    {{ viewModel.details.pendingPaymentExpiryDateTime | date :'short' }}
                    or your booking will be cancelled.
                }
            </span>
        </div>
        <div
            *ngIf="viewModel.showPendingCardDetailsStatus"
            class="vstack gap-2"
        >
            <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                <img
                    class="d-block d-sm-none"
                    ngSrc="assets/clock/small_orange.svg"
                    height="24"
                    width="24"
                    alt="Requested"
                >
                <img
                    class="d-none d-sm-block"
                    ngSrc="assets/clock/large_orange.svg"
                    height="48"
                    width="48"
                    alt="Requested"
                >
                <h3 class="m-0 fw-semibold">Pending Card Details</h3>
            </div>
            <span
                *ngIf="viewModel.details.cancellationCharge"
                class="text-muted"
            >
                We require a card to secure your booking.
                @if (viewModel.details.pendingPaymentExpiryDateTime) {
                    <br/>
                    Please confirm by
                    {{ viewModel.details.pendingPaymentExpiryDateTime | date :'short' }}
                    or your booking will be cancelled.
                }
            </span>
            <span
                *ngIf="viewModel.details.cancellationCharge"
                class="text-muted"
            >
                Cancelling or not showing up before
                {{ viewModel.details.cancellationChargeCutOffTime | date:'short' }}
                may incur a cancellation fee of
                <span class="fw-bold">
                    {{ viewModel.details.cancellationCharge.toFormat() }}.
                </span>
            </span>
        </div>
        <ng-container
            *ngIf="viewModel.showReminderConfirmation"
        >
            <div class="d-flex flex-row flex-sm-column gap-2 gap-sm-1 justify-content-center align-items-center">
                <h2 class="m-0 text-success display-4">
                    <i class="bi bi-check-circle"></i>
                </h2>
                <h3 class="m-0 fw-semibold">
                    Thanks!
                </h3>
            </div>
            <h3 class="text-muted">
                We can't wait to see you there.
            </h3>
        </ng-container>
    </div>
    <div
        *ngIf="viewModel.details as details"
        class="bg-light rounded vstack"
    >
        <div class="p-4 vstack gap-4">
            <div class="vstack gap-2">
                <h4 class="m-0 text-info fw-semibold">
                    {{ details.bookingName }}
                </h4>
                <div class="vstack gap-1 fs-5">
                    <span>
                        Table for <strong>{{ details.partySize | i18nPlural : partySizePluralMapping }}</strong>
                        <ng-container
                            *ngIf="details.areaName"
                        >
                            in
                            <strong>{{ details.areaName }}</strong>
                        </ng-container>
                        on
                        <strong
                            class="text-nowrap"
                            data-testid="booking-date-time"
                        >
                            {{ details.dateTime | date:'fullDate' }}
                        </strong>,
                        <strong
                            class="text-nowrap"
                            data-testid="booking-start-time"
                        >
                            {{ details.dateTime | date:'shortTime' }}
                        </strong>
                        -
                        <strong
                            class="text-nowrap"
                            data-testid="booking-end-time"
                        >
                            {{ details.endTime | date:'shortTime' }}
                        </strong>
                    </span>
                    <div
                        *ngIf="details.reasonName"
                        class="vstack"
                    >
                        <small class="text-muted">
                            Reason:
                        </small>
                        <small class="fw-semibold">
                            {{ details.reasonName }}
                        </small>
                    </div>
                    <div
                        *ngIf="details.eventName"
                        class="vstack"
                    >
                        <small class="text-muted">
                            Event:
                        </small>
                        <small class="fw-semibold">
                            {{ details.eventName }}
                        </small>
                    </div>
                    <div
                        *ngIf="viewModel.details.depositCharge"
                        class="vstack"
                    >
                        <small class="text-muted">
                            Deposit:
                        </small>
                        <small class="fw-semibold">
                            {{ viewModel.details.depositCharge | currencyUnit }}
                            <ng-container
                                *ngIf="!details.depositDatePaid && !details.depositDateRefunded"
                            >
                                <span class="text-muted">
                                    (Unpaid)
                                </span>
                            </ng-container>
                            <ng-container
                                *ngIf="details.depositDatePaid && !details.depositDateRefunded"
                            >
                                <span class="text-muted">
                                    (Paid)
                                </span>
                            </ng-container>
                            <ng-container
                                *ngIf="details.depositDateRefunded"
                            >
                                <span class="text-muted">
                                    (Refunded)
                                </span>
                            </ng-container>
                        </small>
                    </div>
                </div>
                <div class="text-muted">
                    {{ details.venueAddress | addressString }}
                </div>
            </div>
            <ng-template
                *ngTemplateOutlet="diningInformation; context: { $implicit: details, viewModel: viewModel }"
            >
            </ng-template>
            <div class="vstack gap-3">
                <div class="vstack gap-2">
                    <button
                        *ngIf="viewModel.showModifyBookingButton"
                        class="btn btn-outline-dark"
                        type="button"
                        role="button"
                        (click)="modifyBookingClicked(viewModel.details)"
                    >
                        Modify Booking
                    </button>
                    <button
                        *ngIf="viewModel.showViewBookingButton"
                        class="btn btn-outline-dark"
                        type="button"
                        role="button"
                        (click)="viewBookingClicked(viewModel.details)"
                    >
                        Modify Booking
                    </button>
                    <button
                        *ngIf="viewModel.showGetDirectionsButton"
                        class="btn btn-outline-dark"
                        type="button"
                        role="button"
                        (click)="getDirectionsClicked(viewModel)"
                    >
                        Get Directions
                    </button>
                </div>
                <div class="vstack gap-2">
                    <button
                        *ngIf="viewModel.showBookAgainButton"
                        class="btn btn-primary"
                        type="button"
                        role="button"
                        (click)="bookAgainClicked(viewModel)"
                    >
                        Make Another Booking
                    </button>
                    <button
                        *ngIf="viewModel.showSaveCardDetailsButton"
                        class="btn btn-info"
                        type="button"
                        role="button"
                        (click)="confirmWithCard(viewModel)"
                    >
                        Enter Card Details
                    </button>
                    <button
                        *ngIf="viewModel.showCancelButton"
                        class="btn btn-danger"
                        type="button"
                        role="button"
                        (click)="cancelBookingClicked(viewModel)"
                    >
                        Cancel Booking
                    </button>
                    <button
                        *ngIf="viewModel.showPayDepositButton"
                        class="btn btn-info"
                        type="button"
                        role="button"
                        (click)="confirmWithCard(viewModel)"
                    >
                        Pay Deposit
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template
    #diningInformation
    let-details
    let-viewModel=viewModel
>
<div class="vstack gap-2">
    <div
        *ngIf="details.cancellationChargeCutOffTime"
        class="text-muted fw-semibold"
    >
        If you cancel this booking after
        {{ details.cancellationChargeCutOffTime | date:'shortTime' }}
        on
        {{ details.cancellationChargeCutOffTime | date:'fullDate' }},
        you will be charged a cancellation fee of
        {{ details.cancellationCharge | currencyUnit }}.
    </div>
    @if (details.depositRefundPolicy) {
        <div class="quill-flush">
            <quill-view
                [content]="details.depositRefundPolicy"
            >
            </quill-view>
        </div>
    } @else {
        @if (viewModel.showPendingDepositStatus) {
            <span
                *ngIf="details.depositRefundCutOffTime"
            >
                Cancellations or no-shows after
                {{ details.depositRefundCutOffTime | date :'short' }}
                will not be refunded.
            </span>
            <span
                *ngIf="!details.depositRefundCutOffTime"
            >
                Cancellations or no-shows will not be refunded.
            </span>
        } @else if (details.depositAmount) {
            <div
                *ngIf="details.depositRefundCutOffTime"
                class="text-muted fw-semibold"
            >
                If you cancel this booking after
                {{ details.depositRefundCutOffTime | date:'shortTime' }}
                on
                {{ details.depositRefundCutOffTime | date:'fullDate' }},
                you will not be refunded your deposit.
            </div>
            <div
                *ngIf="!details.depositRefundCutOffTime"
                class="text-muted fw-semibold"
            >
                If you cancel this booking, you will not be refunded your deposit.
            </div>
        }
    }
    <span
        *ngIf="details.reasonDiningInformation"
        class="text-muted"
        [innerHTML]="details.reasonDiningInformation"
    >
    </span>
    <span
        *ngIf="details.venueDiningInformation"
        class="text-muted"
        [innerHTML]="details.venueDiningInformation"
    >
    </span>
    <div
        *ngIf="!details.venueDiningInformation"
        class="text-muted"
    >
        If you have any questions about your booking,
        please contact {{ details.venueName }}
        at
        <a
            [href]="'tel:' + details.venuePhoneNumber"
            class="text-decoration-none fw-bold text-info"
        >
            {{ details.venuePhoneNumber }}
        </a>
    </div>
</div>
</ng-template>
