<div class="modal-header">
    <h5 class="modal-title fw-semibold">
        {{ title }}
    </h5>
    <button
        type="button"
        class="btn-close"
        (click)="dismiss()"
    >
    </button>
</div>
<div class="modal-body">
    <div class="vstack gap-2">
        <quill-view
            [content]="content"
        >
        </quill-view>
    </div>
</div>
<div class="modal-footer">
    <button
        class="btn btn-outline-secondary"
        type="button"
        (click)="dismiss()"
    >
        <span>
            Dismiss
        </span>
    </button>
</div>
