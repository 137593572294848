<app-spinner-overlay
    [showSpinner]="isSaving"
>
    <ng-container
        *ngIf="paymentDetailsFormError"
    >
        <div
            class="alert alert-danger"
            role="alert"
            data-test="payment-details-form-error"
        >
            <div class="hstack gap-2 justify-content-between">
                <div>{{ paymentDetailsFormError }}</div>
                <button
                    type="button"
                    class="btn-close m-0"
                    (click)="clearFormErrors()"
                >
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container
        *ngIf="bookingSlotUnavailableWarning"
    >
        <div
            class="alert alert-warning"
            role="alert"
            data-test="booking-slot-unavailable-warning"
        >
            <div class="hstack gap-2 justify-content-between">
                <div>{{ bookingSlotUnavailableWarning }}</div>
                <button
                    type="button"
                    class="btn-close m-0"
                    (click)="clearFormErrors()"
                >
                </button>
            </div>
        </div>
    </ng-container>
    <form
        appVenueColour
        [primaryColour]="venue?.primaryColour"
        [formGroup]="form"
        class="h-100 vstack gap-3"
    >
        <div class="vstack gap-2">
            <h5
                *ngIf="showTitle"
            >
                Card Details
            </h5>
            <div class="vstack gap-2">
                <div class="vstack gap-2 rounded p-3 bg-light flex-grow-0">
                    <span class="badge bg-dark-subtle text-dark-emphasis lh-sm me-auto fw-semibold">
                        Card Details Required
                    </span>
                    <label class="text-muted">
                        We require a card to secure your booking.
                        <br/>
                        Cancellations or no-shows
                        <ng-container
                            *ngIf="cancellationChargeCutOffHours !== null"
                        >
                            with less than
                            {{ cancellationChargeCutOffHours | i18nPlural : hoursPluralMapping }}
                            notice
                        </ng-container>
                        may be charged
                        <span class="fw-bold">
                            {{ cancellationChargeAmount.toFormat() }}
                            <ng-container
                                *ngIf="cancellationChargeIsPerCover"
                            >
                                per person</ng-container></span>.
                    </label>
                </div>
                <div
                    class="position-relative vstack justify-content-start justify-content-sm-center"
                    style="min-height: 150px; transition: none;"
                >
                    <div
                        id="payment-intent"
                    >
                    </div>
                    <div
                        id="payment-intent-spinner"
                        class="align-self-center position-absolute"
                        style="margin-top: -10px;"
                    >
                        <div
                            class="spinner-border"
                            role="status"
                        >
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vstack justify-content-end">
            <div class="hstack gap-2">
                <button
                    *ngIf="showBackButton"
                    type="button"
                    class="btn btn-secondary w-auto"
                    (click)="onBackSelected()"
                >
                    Back
                </button>
                <div
                    *ngIf="showBackButton"
                    class="flex-grow-1 ms-auto me-auto"
                >
                </div>
                <button
                    type="button"
                    class="btn btn-primary"
                    [ngClass]="showBackButton ? 'w-auto' : 'flex-grow-1'"
                    [disabled]="!form.valid || !cardDetailsAreComplete"
                    (click)="onBookSelected()"
                >
                    Book
                </button>
            </div>
        </div>
    </form>
</app-spinner-overlay>
