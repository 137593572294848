import { Component, Input, OnInit } from '@angular/core'
import { Venue } from '../domain/Venue'
import { Voucher } from '../domain/Voucher'
import { SKU } from '../domain/SKU'

@Component({
    selector: 'app-voucher-card',
    templateUrl: './voucher-card.component.html',
})
export class VoucherCardComponent implements OnInit {

    @Input() venue!: Venue
    @Input() voucher!: Voucher
    @Input() sku: SKU | null = null
    expiryDateFromNow: Date | null = null

    ngOnInit() {
        if (this.voucher.expiryMonths !== null) {
            this.expiryDateFromNow = new Date()
            this.expiryDateFromNow.setMonth(this.expiryDateFromNow.getMonth() + this.voucher.expiryMonths)
        } else {
            this.expiryDateFromNow = null
        }
    }
}
