import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Sale } from '../domain/Sale'
import { Venue } from '../domain/Venue'
import { Voucher } from '../domain/Voucher'
import { SKU } from '../domain/SKU'

@Component({
    selector: 'app-voucher-payment-confirmation',
    templateUrl: './voucher-payment-confirmation.component.html',
})
export class VoucherPaymentConfirmationComponent implements OnChanges {

    @Input() venue!: Venue
    @Input() voucher!: Voucher
    @Input() sale!: Sale
    sku: SKU | null = null

    constructor() { }

    ngOnChanges() {
        if (this.sale.order.lineItems.length === 0) {
            return
        }
        const skuIdSoldBySale = this.sale.order.lineItems[0].skuId
        this.sku = this.voucher.getSKUWithId(skuIdSoldBySale)
    }
}
