import { Availability } from '../domain/Availability'
import { AvailabilityService } from '../services/availability.service'
import { BookingDetails } from '../domain/BookingDetails'
import { BookingRequestFactory } from '../domain/BookingRequestFactory'
import { BookingService } from '../services/booking.service'
import { BookingSlot } from '../domain/BookingSlot'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Context } from '../domain/Context'
import { ContextService } from '../services/ContextService'
import {
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms'
import { Helper } from '../utilities/helper'
import {
    Observable,
    Subject,
    combineLatest,
    combineLatestWith,
    finalize,
    switchMap, takeUntil,
} from 'rxjs'
import { PatternValidators } from '../utilities/pattern-validators'
import { Question } from '../domain/Question'
import { RouterContextService } from '../services/RouterContextService'
import { startWith } from 'rxjs/operators'

export enum FormStep {
    BookingSlot = 'BookingSlot',
    BookingPolicy = 'BookingPolicy',
    BookingDetails = 'BookingDetails',
    PaymentDetails = 'PaymentDetails',
    Deposit = 'Deposit',
    Confirmation = 'Confirmation',
}

@Component({
    selector: 'app-booking-form',
    templateUrl: './booking-form.component.html',
    providers: [{
        provide: ContextService,
        useClass: RouterContextService,
    }],
})
export class BookingFormComponent implements OnInit, OnDestroy {

    availability!: Observable<Availability>
    isLoadingAvailability = false
    form: FormGroup
    step: FormStep
    formStep = FormStep
    bookingDetails: BookingDetails | null
    primaryColour: string | null = null
    private onDestroy$ = new Subject<void>()

    constructor(
        private availabilityService: AvailabilityService,
        private bookingService: BookingService,
        private contextService: ContextService,
        private fb: FormBuilder
    ) {
        this.step = FormStep.BookingSlot
        this.form = this.makeForm()
        this.bookingDetails = null
        this.bindContextAndSelectedDateToAvailability()
        this.bindAvailabilityToForm()
        this.bindBookingSlotToFormStep()
        this.bindSelectionToCancellationChargeRequired()
        this.bindSelectionToDepositRequired()
        this.bindVenueQuestionsToForm()
    }

    ngOnInit() {
        this.bindAvailabilityToPhoneNumberRequired()
        this.bindVenueReasonsToReasonRequired()
    }

    ngOnDestroy() {
        this.onDestroy$.next()
        this.onDestroy$.complete()
    }

    areaSelected() {
        this.step = FormStep.BookingDetails
    }

    goBackFromBookingDetails() {
        this.form.get('BookingSlot')!.get('bookingSlot')!.setValue(null)
    }

    goNextFromBookingDetails() {
        const paymentDetailsRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('paymentDetailsRequired') as FormControl
        const depositRequiredControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('depositRequired') as FormControl
        if (paymentDetailsRequiredControl.value) {
            this.step = FormStep.PaymentDetails
        } else if (depositRequiredControl.value) {
            this.step = FormStep.Deposit
        } else {
            this.step = FormStep.Confirmation
        }
    }

    goBackFromPaymentDetails() {
        this.step = FormStep.BookingDetails
    }

    goBackFromDeposit() {
        this.step = FormStep.BookingDetails
    }

    bookButtonSelected() {
        this.contextService.context()
            .pipe(
                switchMap(context => {
                    return this.requestBooking(context)
                })
            )
            .subscribe(bookingDetails => {
                this.bookingDetails = bookingDetails
                this.step = FormStep.Confirmation
            })
    }

    bookAgainSelected() {
        location.reload()
    }

    private makeForm() {
        return this.fb.group({
            'BookingSlot': this.fb.group({
                availability: new FormControl(null, Validators.required),
                partySize: new FormControl(null, Validators.required),
                selectedDate: new FormControl(Helper.makeNgbDate(new Date()), Validators.required),
                selectedTime: new FormControl(null, Validators.required),
                selectedReasonId: new FormControl(null, Validators.required),
                selectedAreaId: new FormControl(null, Validators.required),
                bookingSlot: new FormControl(null, Validators.required),
            }),
            'BookingDetails': this.fb.group({
                paymentDetailsRequired: new FormControl(false, Validators.required),
                depositRequired: new FormControl(false, Validators.required),
                firstName: new FormControl(null, Validators.required),
                lastName: new FormControl(null, Validators.nullValidator),
                emailAddress: new FormControl(null, [Validators.required, Validators.email]),
                phoneNumber: new FormControl(null),
                notes: new FormControl(null),
                questions: new FormArray([]),
                agreedToMarketing: new FormControl(false, Validators.required),
                agreedToTerms: new FormControl(false, Validators.requiredTrue),
            }),
            'PaymentDetails': this.fb.group({
                paymentMethodId: new FormControl(null, null),
            }),
            'Deposit': this.fb.group({
                paymentMethodId: new FormControl(null, null),
            }),
        })
    }

    private requestBooking(context: Context) {
        const factory = new BookingRequestFactory(this.form)
        const request = factory.make()
        const bookingSlotForm = this.form.get(FormStep[FormStep.BookingSlot]) as FormGroup
        const areaId = bookingSlotForm.get('selectedAreaId')?.value
        return this.bookingService.requestBooking(
            context,
            areaId,
            request
        )
    }

    private bindContextAndSelectedDateToAvailability() {
        const getContext = this.contextService.context()
        const getSelectedDate = this.form.get('BookingSlot')!
            .get('selectedDate')!
            .valueChanges
            .pipe(
                startWith(Helper.makeNgbDate(new Date()))
            )
        this.availability = combineLatest(
            [
                getContext,
                getSelectedDate,
            ]
        )
            .pipe(
                switchMap((values) => {
                    const date = Helper.makeDate(values[1])
                    this.isLoadingAvailability = true
                    return this.availabilityService.getAvailability(
                        values[0].organisationId,
                        values[0].businessId,
                        values[0].venueId,
                        date,
                        date
                    )
                        .pipe(
                            finalize(() => this.isLoadingAvailability = false)
                        )
                })
            )
    }

    private bindAvailabilityToForm() {
        this.availability.subscribe((availability) => {
            this.form.get('BookingSlot')!.get('availability')!.setValue(availability)
        })
    }

    private bindBookingSlotToFormStep() {
        this.form.get('BookingSlot')?.get('bookingSlot')!.valueChanges.subscribe((bookingSlot) => {
            if (bookingSlot === null) {
                this.step = FormStep.BookingSlot
            } else {
                this.step = FormStep.BookingDetails
            }
        })
    }

    private bindSelectionToCancellationChargeRequired() {
        const venueControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('availability') as FormControl<Availability>
        const areaIdControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedAreaId') as FormControl<string>
        const partySizeControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('partySize') as FormControl<number>
        const reasonIdControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl<string>
        const bookingSlotControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('bookingSlot') as FormControl<BookingSlot>
        combineLatest(
            [
                venueControl.valueChanges,
                areaIdControl.valueChanges,
                partySizeControl.valueChanges,
                reasonIdControl.valueChanges.pipe(startWith(null)),
                bookingSlotControl.valueChanges,
            ]
        )
            .subscribe(([availability, areaId, partySize, reasonId, bookingSlot]) => {
                const paymentDetailsRequiredControl = this.form
                    .get(FormStep[FormStep.BookingDetails])
                    ?.get('paymentDetailsRequired') as FormControl<boolean>
                const reservableOption = availability.reservableOptionWithConfiguration(
                    areaId,
                    partySize,
                    reasonId,
                    null,
                    bookingSlot.dateTime
                )
                let required = false
                if (reservableOption !== null) {
                    required = reservableOption.cancellationChargeAmount !== null
                }
                paymentDetailsRequiredControl.setValue(required)
            })
    }

    private bindSelectionToDepositRequired() {
        const venueControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('availability') as FormControl<Availability>
        const areaIdControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedAreaId') as FormControl<string>
        const partySizeControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('partySize') as FormControl<number>
        const reasonIdControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl<string>
        const bookingSlotControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('bookingSlot') as FormControl<BookingSlot>
        combineLatest(
            [
                venueControl.valueChanges,
                areaIdControl.valueChanges,
                partySizeControl.valueChanges,
                reasonIdControl.valueChanges.pipe(startWith(null)),
                bookingSlotControl.valueChanges,
            ]
        )
            .subscribe(([availability, areaId, partySize, reasonId, bookingSlot]) => {
                const reservableOption = availability.reservableOptionWithConfiguration(
                    areaId,
                    partySize,
                    reasonId,
                    null,
                    bookingSlot.dateTime
                )
                let required = false
                if (reservableOption !== null) {
                    required = reservableOption.depositAmount !== null
                }
                const depositRequiredControl = this.form
                    .get(FormStep[FormStep.BookingDetails])
                    ?.get('depositRequired') as FormControl<boolean>
                depositRequiredControl.setValue(required)
            })
    }

    private bindAvailabilityToPhoneNumberRequired() {
        const validators = [
            PatternValidators.nonAlphabetical,
        ]
        const availabilityControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('availability') as FormControl
        const phoneNumberControl = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('phoneNumber') as FormControl
        availabilityControl.valueChanges
            .subscribe(availability => {
                this.primaryColour = availability.venue.primaryColour
                if (availability.venue.phoneNumberRequired) {
                    phoneNumberControl.setValidators([...validators, Validators.required])
                } else {
                    phoneNumberControl.setValidators(validators)
                }
            })
    }

    private bindVenueReasonsToReasonRequired() {
        const reasonControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl
        this.availability
            .pipe(
                takeUntil(this.onDestroy$)
            )
            .subscribe(availability => {
                if (availability.venue.reasons.length > 0) {
                    reasonControl.setValidators([Validators.required])
                } else {
                    reasonControl.clearValidators()
                }
            })
    }

    private bindVenueQuestionsToForm() {
        const questionControls = this.form
            .get(FormStep[FormStep.BookingDetails])
            ?.get('questions') as FormArray
        const reasonControl = this.form
            .get(FormStep[FormStep.BookingSlot])
            ?.get('selectedReasonId') as FormControl
        const selectedReasonId = reasonControl.valueChanges
            .pipe(startWith(null))
        this.availability
            .pipe(
                combineLatestWith(selectedReasonId),
                takeUntil(this.onDestroy$)
            )
            .subscribe(([availability, selectedReasonId]) => {
                const questions = availability.venue.questionsToAskWhenBookingForConfiguration(
                    selectedReasonId,
                    null
                )
                questionControls.clear()
                questions.forEach(question => {
                    let validators = this.validatorsForQuestion(question)
                    questionControls.push(this.fb.group({
                        question: new FormControl(question, Validators.required),
                        answer: new FormControl(null, validators),
                    }))
                })
            })
    }

    private validatorsForQuestion(question: Question) {
        const isRequired = question.required && !question.isTickable
        const isRequiredTrue = question.required && question.isTickable
        let validators: ValidatorFn[] = []
        if (isRequired) {
            validators.push(Validators.required)
        }
        if (isRequiredTrue) {
            validators.push(Validators.requiredTrue)
        }
        return validators
    }
}
