import { Component, inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
    selector: 'app-rich-text-modal',
    templateUrl: './rich-text-modal.component.html',
})
export class RichTextModalComponent {

    @Input() title!: string
    @Input() content!: string
    private modal = inject(NgbActiveModal)

    dismiss() {
        this.modal.dismiss()
    }
}
