<div
    class="vstack gap-2"
    [formGroup]="form"
>
    <div class="row g-0">
        <div class="col-md-7">
            <div class="p-4 px-lg-5 vstack gap-3">
                <div
                    class="d-flex justify-content-center"
                >
                    <app-voucher-card
                        class="d-flex"
                        [style.height.px]="200"
                        [venue]="venue"
                        [voucher]="voucher"
                        [sku]="selectedSKU"
                    >
                    </app-voucher-card>
                </div>
                @if (voucher.description) {
                    <div class="vstack gap-2">
                        <h6 class="fw-semibold">
                            Description
                        </h6>
                        <p
                            *ngIf="voucher.description"
                            class="mb-0 mb-md-2"
                        >
                            <span [innerHTML]="
                                descriptionShowMore
                                    ? voucher.description
                                    : (voucher.description | slice :0 : descriptionCharacterLimit) + (voucher.description.length > descriptionCharacterLimit ? '...' : '')
                            ">
                            </span>
                            <button
                                *ngIf="voucher.description.length > descriptionCharacterLimit"
                                class="btn btn-sm btn-link p-0 ms-1 lh-base text-body"
                                (click)="descriptionShowMore = !descriptionShowMore"
                            >
                                {{ descriptionShowMore ? 'Read less' : 'Read more' }}
                            </button>
                        </p>
                    </div>
                }
                <div class="d-md-none vstack gap-3">
                    <hr class="border-dark-subtle m-0"/>
                    <ng-container
                        *ngTemplateOutlet="details"
                    >
                    </ng-container>
                    <hr class="border-dark-subtle m-0"/>
                </div>
                @if (voucher.isOnSale) {
                    @let sku = voucher.lowestPricedSKU;
                    @let lowestSKUPrice = null | currencyUnit : sku?.price : 'GBP';
                    <div class="d-flex flex-column flex-md-row gap-3 gap-lg-4">
                        <div class="vstack flex-grow-0">
                            <h6 class="text-secondary m-0">
                                From
                            </h6>
                            <span class="fw-semibold fs-5">
                                {{ lowestSKUPrice }}
                            </span>
                        </div>
                        <div class="vr bg-secondary d-none d-md-block"></div>
                        <div class="vstack gap-2">
                            @if (form.get('skuId')?.touched && form.get('skuId')?.invalid) {
                                <h6 class="text-secondary m-0">
                                    <small class="text-danger">
                                        Please select a value
                                    </small>
                                </h6>
                            }
                            <div class="position-relative btn-group dropdown">
                                <button
                                    class="btn btn-outline-secondary dropdown-toggle"
                                    type="button"
                                    (click)="skuSelect.click()"
                                >
                                    @if (!selectedSKU) {
                                        Select a value
                                    } @else {
                                        {{ null | currencyUnit : selectedSKU.price : 'GBP' }}
                                    }
                                </button>
                                <select
                                    #skuSelect
                                    class="form-select btn opacity-0 position-absolute top-0 start-0 w-100 h-100"
                                    formControlName="skuId"
                                >
                                    @for (sku of voucher.skusSortedByPriceLowestFirst; track sku.id) {
                                        <option
                                            [value]="sku.id"
                                        >
                                            {{ null | currencyUnit : sku.price : 'GBP' }}
                                        </option>
                                    }
                                </select>
                            </div>
                            <button
                                class="btn btn-primary"
                                appVenueColour
                                [primaryColour]="venue.primaryColour"
                                (click)="goToCheckout()"
                            >
                                Proceed to Checkout
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div class="d-none d-md-block col-md-5 bg-light">
            <div class="p-4">
                <ng-container
                    *ngTemplateOutlet="details"
                >
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template
    #details
>
    <div class="vstack gap-1">
        <ng-container *ngIf="voucher.expiryMonths !== null">
            <h6 class="fw-semibold">
                Voucher Details
            </h6>
            <p>
                This voucher is valid for {{ voucher.expiryMonths }} month{{ voucher.expiryMonths > 1 ? 's' : '' }} from the date of purchase.
            </p>
        </ng-container>
        <h6 class="fw-semibold">
            Delivery Information
        </h6>
        <p>
            Delivery is free and will be sent to you, or your preferred recipient, via email.
        </p>
        @if (voucher.policy && voucher.policy.length) {
            <label class="text-muted">
                For terms and conditions of use, see below.
            </label>
            <ul class="list-group">
                <a
                    class="list-group-item list-group-item-action hstack justify-content-between"
                    role="button"
                    (click)="showPolicy()"
                >
                    <span class="fw-semibold">
                        Terms & Conditions
                    </span>
                    <img
                        ngSrc="assets/right/normal.svg"
                        width="16"
                        height="16"
                        alt="Terms & Conditions"
                    >
                </a>
            </ul>
        }
    </div>
</ng-template>
